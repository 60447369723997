@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

body {
  background-color: #0e0e1e;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
}

h1 {
  text-align: center;
  color: #00d1b2;
  margin: 20px 0;
  font-size: 36px;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding: 20px;
}

.match-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.match {
  background: linear-gradient(145deg, #1a1a2e, #0e0e1e);
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow: hidden;
  position: relative;
  transition: box-shadow 0.3s;
}

.match.win {
  border-left: 5px solid #00d1b2;
}

.match.lose {
  border-left: 5px solid #ff3860;
}

.match:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
}

.player {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, box-shadow 0.3s;
  position: relative;
}

.player img {
  border-radius: 50%;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border: 2px solid #00d1b2;
}

.player-details {
  flex-grow: 1;
}

.player p {
  margin: 0;
  font-size: 13px;
  font-weight: 500;
  color: #f8f8f8;
}

.player-stats {
  margin-top: 5px;
  font-size: 12px;
  color: #cccccc;
}

.player:hover {
  background-color: #3b3b6e;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.go-to-profile-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 5px 10px;
  background-color: #00d1b2;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s;
}

.go-to-profile-button:hover {
  background-color: #00b39f;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #1a1a2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  max-width: 800px;
  width: 100%;
  text-align: center;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

.modal-avatar {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 2px solid #00d1b2;
  margin-bottom: 20px;
}

.modal-content {
  color: #fff;
}

.modal-content h2 {
  margin: 10px 0;
  color: #00d1b2;
}

.go-to-profile-button-modal {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #00d1b2;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.go-to-profile-button-modal:hover {
  background-color: #00b39f;
}

.container-modal {
  max-width: 800px;
  margin: 0 auto;
}

.match-grid-modal {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.loading {
  text-align: center;
  font-size: 18px;
  color: #00d1b2;
}

.error {
  text-align: center;
  font-size: 18px;
  color: #ff3860;
}

.search-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.search-container input {
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
}

.search-container button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #00d1b2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  transition: background-color 0.3s;
}

.search-container button:hover {
  background-color: #00b39f;
}
