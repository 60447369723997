@keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  .animate__fadeIn {
    animation-name: fadeIn;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  .animate__fadeInLeft {
    animation-name: fadeInLeft;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes shakeX {
    from,
    to {
      transform: translate3d(0, 0, 0);
    }
  
    10%,
    30%,
    50%,
    70%,
    90% {
      transform: translate3d(-10px, 0, 0);
    }
  
    20%,
    40%,
    60%,
    80% {
      transform: translate3d(10px, 0, 0);
    }
  }
  
  .animate__shakeX {
    animation-name: shakeX;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .animate__zoomIn {
    animation-name: zoomIn;
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  