@import 'tailwindcss/tailwind.css';
@import 'animate.css/animate.min.css';

html, body {
  @apply bg-dark-bg text-primary;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#root {
  width: 100%;
}
